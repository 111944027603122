<template>
  <v-text-field
    v-bind="$input"
     v-on="$listeners"
    :label="$attrs.label"    
    :disabled="disabled"
    :readonly="readonly"
    v-model="displayValue"
    @blur="focus_lost">  
  </v-text-field>  
</template>


<script>

export default {
  props: {    
    value: { type: [String, Number], default: "" },      
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },

  data() {
    return {
      cif: '',
    }
  },

  computed: {
    displayValue: {
      get: function() {         
        return this.value;
      },
      
      set: function(modifiedValue) {         
        this.$emit("input", modifiedValue);
      }
    }
  },
  
  methods: {
    focus_lost(event) {     
      let ctrl= document.getElementById(event.srcElement.id);
      if (ctrl.value=='') return;        
      
      // valido cif/dni/nie
      //if (!this.valida_cif(ctrl.value)) {       
      if (!this.validaCIF(ctrl.value)) {       
        this.$root.$alert.open('NIF / CIF Incorrecto ' + ctrl.value, 'error', 1500);          
        setTimeout(function() { ctrl.focus(); }, 1);
      }
      //enviamos dato limpio
      this.$emit("input", ctrl.value);
    },

    validaCIF(nif) {
      var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
      var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
      var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
      //var cifRexp = /^[[A-H][J-N][P-S]UVW][0-9]{7}[0-9A-J]$/i;

      var str = nif.toString().toUpperCase();

      //nif / nie
      if (nifRexp.test(str) || nieRexp.test(str)) {
        var nie = str
          .replace(/^[X]/, '0')
          .replace(/^[Y]/, '1')
          .replace(/^[Z]/, '2');

        let letter = str.substr(-1);
        let charIndex = parseInt(nie.substr(0, 8)) % 23;

        return (validChars.charAt(charIndex) === letter);
      }
      
      // cif
      //if (cifRexp.test(str)) {
        return this.isvalidCif(str);
      //}
      /*  let num;
        let controlDigit;
        let sum=0;

        for ( var i = 0; i < 9; i++ ) {
          num[ i ] = parseInt( str.charAt( i ), 10 );
        }          
        // Algorithm for checking CIF codes
        sum = num[ 2 ] + num[ 4 ] + num[ 6 ];
        for ( var count = 1; count < 8; count += 2 ) {
          var tmp = ( 2 * num[ count ] ).toString(),
          secondDigit = tmp.charAt( 1 );
          
          sum += parseInt( tmp.charAt( 0 ), 10 ) + ( secondDigit === '' ? 0 : parseInt( secondDigit, 10 ) );
        }          
        // CIF test
        if ( /^[ABCDEFGHJNPQRSUVW]{1}/.test( str ) ) {
          sum += '';
          controlDigit = 10 - parseInt( sum.charAt( sum.length - 1 ), 10 );
          str += controlDigit;
          return ( num[ 8 ].toString() === String.fromCharCode( 64 + controlDigit ) || num[ 8 ].toString() === str.charAt( str.length - 1 ) );
        }                    
      }*/
      //return false;
    },

    isValidCif(cif) {
      var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
      var digits = cif.substr(1, cif.length - 2);
      var letter = cif.substr(0, 1);
      var control = cif.substr(cif.length - 1);
      var sum = 0;
      var i;
      var digit;

      if (!letter.match(/[A-Z]/)) {
        return false;
      }

      for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);

        if (isNaN(digit)) {
          return false;
        }

        if (i % 2 === 0) {
          digit *= 2;
          if (digit > 9) {
            digit = parseInt(digit / 10) + (digit % 10);
          }

          sum += digit;
        } else {
          sum += digit;
        }
      }

      sum %= 10;
      if (sum !== 0) {
        digit = 10 - sum;
      } else {
        digit = sum;
      }

      if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
      }
      if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
      }

      return String(digit) === control || letters[digit] === control;
    },


    // 
    valida_cif(nif) {
      this.cif=nif;
      if (nif.charAt(0)=='*') return true;
      nif = nif.toUpperCase().replace(/[_\W\s]+/g, '');
      this.cif=nif; 
      //dni + nie
      if(/^(\d|[XYZ])\d{7}[A-Z]$/.test(nif)) {
          let num = nif.match(/\d+/);
          num = (nif[0]!='Z'? nif[0]!='Y'? 0: 1: 2)+num;
          if(nif[8]=='TRWAGMYFPDXBNJZSQVHLCKET'[num%23]) {
              return /^\d/.test(nif)? 'DNI': 'NIE';
          }
      }
      //cif
      //else if(/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(nif)) {
      else if (/^[[A-H][J-N][P-S]UVW][0-9]{7}[0-9A-J]$/.test(nif)) {
          return true;   
          
          /*
          var par = 0; 
          var non = 0;          
          for (let zz=2;zz<8;zz+=2) {
              par = par+parseInt(nif.charAt(zz));
          }                
          for (let zz=1;zz<9;zz+=2) {
              let nn = 2*parseInt(nif.charAt(zz));
              if (nn > 9) nn = 1+(nn-10);
              non = non+nn;
          }    
          let parcial = par + non;            
          let control = (10 - ( parcial % 10));

          var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
          var control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );

          // Control must be a digit
          if ( letter.match( /[ABEH]/ ) ) {
                return control == control_digit;
          // Control must be a letter
          }
          if ( letter.match( /[KPQS]/ ) ) {
            return control == control_letter;

          $checksum = ((10 - ($checksum % 10)) % 10);
          $checksum = substr($control, ((10 - ($checksum % 10)) % 10), 1);

          let cc=nif.charAt(0);
          if (cc.match(/^[FJKNPQRSUVW]$/)) control+=64;
          
          if (control==10) control=0;              
          return (String(control)==nif.charAt(8));
          */
      }        
      return false;        
    }
  },
}

</script>

